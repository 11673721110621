import { Link } from 'gatsby';
import React, { useState, useContext, useEffect } from 'react';

import BlogButton from '@components/reusable-buttons/blog-button';
import LanguageContext from '../../context/language-context';

const MakeImpact = () => {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Impacting peoples',

      titleSecondHalf: 'life through books  ',
      browseBookButton: 'Browse All Books',
    },
    esp: {
      titleFirstHalf: 'Impactando vidas',
      titleSecondHalf: ' a través de los libros',

      browseBookButton: 'Examinar todos los libros',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="landing-make-impact-outermost">
      <div className="landing-make-impact-holder common-container">
        <div className="landing-make-impact-title">
          <h2>{currentContent?.titleFirstHalf}</h2>
          <h2>{currentContent?.titleSecondHalf}</h2>
          <div className="landing-make-impact-button">
            <Link to="/books">
              <BlogButton buttonText={currentContent?.browseBookButton} bgcolor="white" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MakeImpact;
