import React, { useContext } from 'react';
import { Link } from 'gatsby';
import LanguageContext from '../../context/language-context';

import LeftArrowContact from '@assets/contact/left-arrow';

const AboutUsHero = () => {
  const { contentLanguage } = useContext(LanguageContext);
  return (
    <div className="about-us-background-wrapper-for-outermost">
      <div className="about-us-hero-outermost u-margin-top-lg">
        <div className="about-us-hero-background">
          <div className="about-us-hero-container common-container">
            <ul class="privacy-policy-breadcrumb">
              <Link to="/">
                <li className="opacity-low">{contentLanguage === 'eng' ? 'Home' : 'Hogar'}</li>
              </Link>
              <LeftArrowContact />
              <li>{contentLanguage === 'eng' ? 'About-us' : 'Sobre nosotras'}</li>
            </ul>
            <div className="about-us-hero-nav"></div>
            <div className="about-us-hero-title">
              <h1> {contentLanguage === 'eng' ? 'We are here to help you be ' : 'Estamos aquí para ayudarte a ser'}</h1>
              <h1 className="h1-modified">
                {contentLanguage === 'eng' ? 'better than you were yesterday.' : ' mejor de lo que eras ayer.'}
              </h1>
              <p className="about-us-hero-desc body2"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUsHero;
