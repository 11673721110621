import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import LanguageContext from '../../context/language-context';

const Introduction = () => {
  const [stickyDiv, setStickyDiv] = useState(false);

  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      who: 'Who ',
      arewe: 'are we ?',
      about: 'About',
      aboutText1: `Everyone deserves wellness and good health. We want you to live your best life. At Better Way of Life, our
      team of experts uses scientific research to guide you through your wellness journey. You will be empowered
      and educated to be better. Through decades of research, we have identified principles that create superior
      health. So powerful is our research that`,
      aboutText2: `people who live in high cultural concentrations who follow our principles, live on average, 10 years longer.
      Our books provide a knowledge warehouse of these studies. They are written by medical professionals and
      offer natural solutions to everyday life challenges. Our holistic approach focuses on the root cause of the
      most preventable diseases that our modern society faces. Better way of life is your pathway to a healthy
      MIND, a healthy BODY, and a healthy LIFE. We hope you will join us in the journey to live your best life.`,
      NationalGeographic: 'National Geographic',
      visionaText: ` We want to help everyone be better than yesterday through knowledge, nutrition, exercise, and positive
      thinking.`,
      vision: 'Our Vision',
    },
    esp: {
      who: 'Quienes ',
      arewe: 'somos?',
      about: 'Acerca',
      aboutText1: `Todos merecen bienestar y buena salud. Queremos que vivas tu mejor vida. En 
      Better Way of Life, nuestro equipo de expertos utiliza la investigación científica para
      guiarlo a través de su viaje de bienestar. Serás empoderado y educado para ser 
      mejor. A través de décadas de investigación, hemos identificado principios que 
      crean una salud superior. Tan poderosa es nuestra investigación que`,
      NationalGeographic: 'National Geographic',

      aboutText2: `ha encontrado que las personas que viven en altas concentraciones 
      culturales que siguen nuestros principios, viven en promedio, 10 años más. 
      Nuestros libros proporcionan un almacén de conocimiento de estos estudios. Están 
      escritos por profesionales médicos y ofrecen soluciones naturales a los desafíos de 
      la vida cotidiana. Nuestro enfoque holístico se centra en la causa raíz de las 
      enfermedades más prevenibles que enfrenta nuestra sociedad moderna. Una mejor 
      forma de vida es su camino hacia una MENTE sana, un CUERPO sano y una VIDA 
      sana. Esperamos que se una a nosotros en el viaje para vivir su mejor vida.`,
      visionaText: `Queremos ayudar a todos a ser mejores que ayer a través del conocimiento, la 
      nutrición, el ejercicio y el pensamiento positivo.`,
      vision: 'Nuestra visión',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  useEffect(() => {
    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setStickyDiv(true);
        } else {
          setStickyDiv(false);
        }
      },
      { threshold: [0.9] }
    );

    observer.observe(document.getElementById('visible-div-about-us'));
  }, []);
  return (
    <div className="about-us-into-container common-container" id="visible-div-about-us">
      <div className={stickyDiv ? 'about-us-intro-heading-sticky static-mobile ' : ' opacity-0-about-us static-mobile'}>
        <h2>{currentContent.who} </h2> <h2 className="h2-modified">{currentContent.arewe}</h2>
      </div>
      <div className="about-us-inro-text half-width about-philosophy">
        <div className="about-us-intro-text-content">
          <h4>{currentContent.about}</h4>
          <p className="about-us-intro-desc">
            {currentContent.aboutText1}
            <p className="about-us-intro-desc about-us-intro-desc-modified"> National Geographic</p>{' '}
            {currentContent.aboutText2}
          </p>
        </div>
        <div className="about-us-intro-text-content">
          <h4>{currentContent.vision}</h4>
          <p className="about-us-intro-desc">{currentContent.visionaText}</p>
        </div>
        {/* <div className="about-us-intro-text-content">
          <h4>Our mission</h4>
          <p className="about-us-intro-desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim id porttitor tristique a donec tortor.
            Lobortis id mauris quis pharetra at. Dictumst duis morbi amet blandit egestas nec sollicitudin. Facilisi
            maecenas diam donec laoreet Lobortis id mauris quis pharetra at. Dictumst duis morbi amet blandit egestas
            nec sollicitudin. Facilisi maecenas diam donec laoreet Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Dignissim id porttitor tristique a donec tortor. Lobortis id mauris quis pharetra at. Dictumst duis
            morbi amet blandit egestas nec sollicitudin. Facilisi maecenas diam donec laoreet Lobortis id mauris quis
            pharetra at. Dictumst duis morbi amet blandit egestas nec sollicitudin. Facilisi maecenas diam donec laoree
          </p>
        </div> */}
      </div>
    </div>
  );
};
export default Introduction;
