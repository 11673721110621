import React from 'react';

import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import AboutUsHero from '@components/about-us/about-us-hero';
import Introduction from '@components/about-us/introduction';
import OurPhilosophy from '@components/about-us/our-philosophy';
import WriterPublishers from '@components/about-us/writer-publisher';
import MakeImpact from '@components/landing-page/make-impact';
const AboutUsPage = () => {
  return (
    <>
      <Seo
        title="About Us - Better Way of Life."
        description="We Want You To Live Your Best Life.Therefore,Better Way of Life Is Your Pathway to Healty Mind,Healthy Body and a Healthy Life."
      />
      <Layout>
        <AboutUsHero />
        <Introduction />
        <OurPhilosophy />
        <WriterPublishers />
        <MakeImpact />
      </Layout>
    </>
  );
};

export default AboutUsPage;
