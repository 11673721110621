import React, { useState, useEffect, useContext } from 'react';

import Nutrition from '@assets/about-us/nutrition.svg';
import Air from '@assets/about-us/air.svg';
import Sunlight from '@assets/about-us/sunlight.svg';
import Temperance from '@assets/about-us/temperance.svg';
import Exercise from '@assets/about-us/exercise.svg';
import Water from '@assets/about-us/water.svg';
import God from '@assets/about-us/god.svg';
import Rest from '@assets/about-us/rest.svg';
import LanguageContext from '../../context/language-context';

const OurPhilosophy = () => {
  const [stickyDiv, setStickyDiv] = useState(false);
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      ourHealth: 'Our health',
      Philosophy: 'Philosophy',
      Nutrition: 'Nutrition',
      Exercise: 'Exercise',
      Water: 'Water',
      Sunlight: 'Sunlight',
      Temperance: 'Temperance',
      Air: 'Air',
      Rest: 'Rest',
      TrustinGod: 'Trust in God',
      foodText: `Food is medicine. It can harm and heal. Proper nutrition is the key to a better life.`,
      exerciseText: `Exercise is not about looking great in a pair of jeans. It is a complete life philosophy about getting
      healthier and happier.`,
      waterText: `The body is 70% water. Proper hydration regulates the body temperature, keeps joints lubricated, prevents
      infections, delivers nutrients to cells, and keeps organs functioning correctly.`,
      sunText: `The sun is vital life support for all plants and animals. Sunlight creates vitamin D and is vitally
      important for the body’s metabolism and hormonal balance.`,
      TemperanceText: `Practice all things in moderation. It is hard to be perfect. Instead, focus on increasing good behaviors
      and reducing destructive behaviors.`,
      airText: `The sun is vital life support for all plants and animals. Sunlight creates vitamin D and is vitally
      important for the body’s metabolism and hormonal balance.`,
      restText: `The body rebuilds and repairs during rest. The more you are active (in thought or exercise), the more rest
      you need. Marathon runners and professional athletes know.`,
      TrustinGodText: `We live in a world full of chaos. To stay sane and be happy, we must practice positive thinking every day.
      Prayer has been proven to be as powerful as long-term behavioral, cognitive therapy.`,
    },
    esp: {
      ourHealth: 'Nuestra filosofía ',
      Philosophy: 'de salud',
      Nutrition: 'Nutrición',
      Exercise: 'Ejercicio',
      Water: 'Agua',
      Sunlight: 'Sol',
      Temperance: 'Temperancia',
      Air: 'Aire',
      Rest: 'Reposo',
      TrustinGod: 'Confiar en Dios',
      foodText: `La comida es medicina. Puede dañar y curar. Una 
      nutrición adecuada es la clave para una vida mejor.`,
      exerciseText: `El ejercicio no se trata de verse bien en un par de pantalones. Es una filosofía de vida completa para ser más saludable y más feliz.`,
      waterText: `El cuerpo es 70% agua. La hidratación adecuada 
      regula la temperatura corporal, mantiene las 
      articulaciones lubricadas, previene infecciones, 
      proporciona nutrientes a las células y mantiene los
      órganos funcionando correctamente.`,
      sunText: `El sol es un soporte de vida vital para todas las 
      plantas y animales. La luz del sol crea vitamina D y
      es de suma importancia para el metabolismo y el 
      equilibrio hormonal del cuerpo.`,
      TemperanceText: `Practique todas las cosas con moderación. Es difícil
      ser perfecto. En su lugar, concéntrese en aumentar
      los buenos comportamientos y reducir los 
      comportamientos destructivos.`,
      airText: `The sun is vital life support for all plants and animals. Sunlight creates vitamin D and is vitally
      important for the body’s metabolism and hormonal balance.`,
      restText: `El cuerpo se reconstruye y repara durante el 
      descanso. Cuanto más activo esté (en pensamiento
      o ejercicio), más descanso necesitará. Los 
      corredores de maratón y los atletas profesionales 
      lo saben.`,
      TrustinGodText: `Vivimos en un mundo lleno de caos. Para 
      mantenernos cuerdos y ser felices, debemos 
      practicar el pensamiento positivo todos los días. Se
      ha demostrado que la oración es tan poderosa 
      como la terapia cognitiva conductual a largo plazo.`,
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  useEffect(() => {
    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setStickyDiv(true);
        } else {
          setStickyDiv(false);
        }
      },
      { threshold: [0.5] }
    );

    observer.observe(document.getElementById('visible-philosophy-div'));
  }, []);
  return (
    <div className="about-us-philosophy-outermost">
      <div className="landing-recommended-books-container common-container" id="visible-philosophy-div">
        <div className="landing-recommended-title-and-button">
          <div
            className={
              stickyDiv
                ? 'make-me-sticky'
                : 'landing-recommended-title about-philosophy-title opacity-0-writer z-index-0'
            }
            id="sticky-writers"
          >
            <h2>{currentContent.ourHealth}</h2>
            <h2 className="h2-modified" id="scrollingContent">
              {currentContent.Philosophy}
            </h2>
          </div>
        </div>

        <div className="landing-recommended-books-list half-width about-philosophy" id="right-align">
          {/* {test.map((item) => {
            return ( */}
          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Nutrition} alt="CUBE" />
            </div>
            <h5>{currentContent.Nutrition}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.foodText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Exercise} alt="CUBE" />
            </div>
            <h5>{currentContent.Exercise}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.exerciseText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Water} alt="CUBE" />
            </div>
            <h5>{currentContent.Water}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.waterText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Sunlight} alt="CUBE" />
            </div>
            <h5>{currentContent.Sunlight}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.sunText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Temperance} alt="CUBE" />
            </div>
            <h5>{currentContent.Temperance}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.TemperanceText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Air} alt="CUBE" />
            </div>
            <h5>{currentContent.Air}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.airText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={Rest} alt="CUBE" />
            </div>
            <h5>{currentContent.Rest}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.restText}
            </p>
          </div>

          <div className="landing-recommended-books-item about-philosophy-item ">
            <div className="recommended-books-image-holder about-us-philosophy-modified ">
              <img src={God} alt="CUBE" />
            </div>
            <h5>{currentContent.TrustinGod}</h5>
            <p className="landing-recommended-books-desc body1 writer-designation about-us-philosophy-desc">
              {currentContent.TrustinGodText}
            </p>
          </div>
          {/* );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophy;
