import { graphql, useStaticQuery } from 'gatsby';
import React, { useState, useContext, useEffect } from 'react';

import Book from '@assets/related-books/related-books.png';
import CloseIcon from '@assets/browse-books/icon-close.svg';
import Backdrop from '../backdrop/backdrop';
import LanguageContext from '../../context/language-context';

const WriterPublishers = () => {
  const data = useStaticQuery(graphql`
    query writers {
      allPrismicAuthor {
        nodes {
          data {
            from_this_author {
              books_from_author {
                document {
                  ... on PrismicBooks {
                    id
                    data {
                      cover_image {
                        fluid {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
            designation {
              text
            }
            headshot {
              fluid {
                src
              }
            }
            title {
              text
            }
            description {
              text
            }
            language
          }
        }
      }
      allPrismicPublishers {
        nodes {
          data {
            title {
              text
            }
            description {
              text
            }
            headshot: publisher_headshot {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `);

  const [allWriters, setAllWriters] = useState([]);
  const { contentLanguage } = useContext(LanguageContext);

  const writers = data.allPrismicAuthor.nodes.concat(data.allPrismicPublishers.nodes);
  const engWriters = data.allPrismicAuthor.nodes
    .filter((item) => item.data.language === 'eng')
    .concat(data.allPrismicPublishers.nodes);
  const espWriters = data.allPrismicAuthor.nodes
    .filter((item) => item.data.language === 'esp')
    .concat(data.allPrismicPublishers.nodes);
  const [showModal, setShowModal] = useState(false);
  const [writerInfo, setWriterInfo] = useState({
    image: '',
    name: '',
    description: '',
    books: [],
  });

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setAllWriters(espWriters);
    } else {
      setAllWriters(engWriters);
    }
  }, [contentLanguage]);

  return (
    <div className="about-writer-publisher-container common-container" id="writers-and-publishers">
      <div className="about-writer-publisher-title">
        <h2>{contentLanguage == 'eng' ? 'Meet our doctors,' : 'Conozca a nuestros médicos,'} </h2>{' '}
        <h2 className="h2-modified">
          {contentLanguage === 'eng' ? 'researchers and authors' : 'investigadores y autores'}
        </h2>
      </div>
      <div className="about-writer-publisher-details ">
        {allWriters.map((writer) => {
          const { designation, title, headshot, description } = writer.data;

          return (
            <div
              className="about-writer-publisher-content"
              onClick={() => {
                setShowModal(!showModal);

                setWriterInfo({
                  image: headshot?.fluid?.src,
                  name: title?.text,
                  description: description?.text,
                  books: writer.data.from_this_author ? writer.data.from_this_author : [],
                });
              }}
            >
              <div className="writer-publisher-image-holder">
                <img src={headshot.fluid.src} alt="writers and publishers" />
              </div>
              <h5 className="writer-publisher-name">{title?.text}</h5>
              <h6 className="writer-publisher-designation body1">{designation?.text}</h6>
            </div>
          );
        })}
      </div>
      <div className={showModal ? 'modal-container' : 'hide'}>
        <div className="about-writers-modal">
          <div className="writer-modal-about">
            <div className="close-icon-modal" onClick={() => setShowModal(!showModal)}>
              <img src={CloseIcon} />
            </div>
            <div className="writer-modal-image-holder">
              <img src={writerInfo.image} alt="writers and publishers" />
            </div>
            <h5>{writerInfo.name}</h5>
            <p className="writer-modal-desc body1">{writerInfo.description}</p>
          </div>
          <div className="writer-modal-books">
            <h5>{writerInfo.books.length === 0 ? '' : 'From this author'}</h5>
            <div className="writer-modal-books-holder">
              {writerInfo.books.map((book) => {
                return (
                  <div className="modal-book-item">
                    <img src={book.books_from_author?.document?.data.cover_image.fluid.src} alt="book by this author" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="backdrop-holder" onClick={() => setShowModal(!showModal)}>
          <Backdrop />
        </div>
      </div>
    </div>
  );
};

export default WriterPublishers;
